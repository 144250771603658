<template>
  <div class="news-detail">
    <Header theme="black" :currentIndex="headerIndex" />
    <NewsBreadcrumb />
    <NewsDetailComponent :detail="detail" />
    <NewsLink :first="first" :prev="prev" :next="next" />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import NewsBreadcrumb from '@/components/NewsBreadcrumb.vue';
import NewsDetailComponent from '@/components/NewsDetail.vue';
import NewsLink from '@/components/NewsLink.vue';
import newsItems from '../utils/news';

export default {
  name: 'NewsDetail',
  components: {
    Header,
    Footer,
    NewsBreadcrumb,
    NewsDetailComponent,
    NewsLink,
  },
  watch: {
    '$route.params.id': {
      handler(id) {
        this.getDetail(id);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {
    const { id } = this.$router.currentRoute.params;
    this.getDetail(id);
  },
  data() {
    return {
      headerIndex: 3,
      detail: {},
      prev: '',
      first: '',
      next: '',
    };
  },
  methods: {
    getDetail(id) {
      this.detail = newsItems.find((it) => id === it.id) || {};
      const detailIndex = newsItems.findIndex((it) => id === it.id);
      this.first = detailIndex === 0 ? '' : newsItems[0].id;
      this.prev = detailIndex > 0 ? newsItems[detailIndex - 1].id : '';
      this.next = detailIndex < newsItems.length - 1 ? newsItems[detailIndex + 1].id : '';
    },
  },
};
</script>
<style lang="less" scoped>
.news-cards {
  margin-top: @size100;
}
.page {
  margin-bottom: @size100;
}
</style>
