<template>
  <div class="links">
    <div class="wrapper">
      <div class="inner">
        <div class="item" @click="onPrev" v-if="prev">上一篇</div>
        <div class="item" @click="onFirst" v-if="first">返回首页</div>
        <div class="item" @click="onNext" v-if="next">下一篇</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsLink',
  props: {
    prev: String,
    next: String,
    first: String,
  },
  methods: {
    onFirst() {
      this.$router.push({ name: 'NewsDetail', params: { id: this.first } });
    },
    onPrev() {
      this.$router.push({ name: 'NewsDetail', params: { id: this.prev } });
    },
    onNext() {
      this.$router.push({ name: 'NewsDetail', params: { id: this.next } });
    },
  },
};
</script>
<style scoped lang="less">
.links {
  width: 100%;
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      padding-top: @size50;
      padding-bottom: @size100;
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      border-top: solid 1px #666;
      .item {
        border: 1px solid #989898;
        padding: 0 10px;
        border-radius: 16px;
        font-size: @font16;
        color: #575757;
        cursor: pointer;
        margin-right: 20px;
        height: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
