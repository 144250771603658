<template>
  <div class="pics">
    <!-- <div class="pics" :style="style"> -->
    <el-carousel height="5rem">
      <el-carousel-item v-for="item in 1" :key="item">
        <div class="item">
          <img
            src="../assets/images/about/pic-1.jpg"
            alt=""
            class="about-img"
          />
          <div class="breadcrumb">
            <span
              class="breadcrumb-item"
              v-for="(name, index) in breadcrumb"
              :key="index"
              >{{ name }}</span
            >
          </div>
          <div class="more" @click="goto">了解更多 ></div>
        </div>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
import { routeUrls } from '../utils/constants';

export default {
  name: 'NewsPics',
  props: {},
  // created() {
  //   this.getHeight();
  //   window.addEventListener('resize', this.getHeight);
  // },
  // destroyed() {
  //   window.removeEventListener('resize', this.getHeight);
  // },
  data() {
    return {
      height: 0,
      breadcrumb: ['首页 >', '新闻资讯'],
      style: {
        marginTop: '90px',
      },
    };
  },
  methods: {
    getHeight() {
      const width = Math.max(window.innerWidth, 960);
      this.height = `${(500 / 1920) * width}px`;
      this.style.marginTop = `${(90 / 1920) * width}px`;
    },
    goto() {
      this.$router.push({ path: routeUrls.mineBusiness });
    },
  },
};
</script>
<style scoped lang="less">
.pics {
  margin-top: @size90;
  width: 100%;
  height: @size500;
  min-height: 250px;
  position: relative;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: auto;
  }
  .pics-btn {
    margin-top: @size90;
    width: @size260;
    min-width: 130px;
    height: @size70;
    min-height: 35px;
    background: #ffffff;
    border-radius: @size35;
    font-size: @size30;
    font-weight: 700;
    color: #0066dd;
    text-align: center;
    line-height: 70px;
  }
}
.breadcrumb {
  position: absolute;
  top: @size80;
  left: @size150;
  font-size: @font14;
  .breadcrumb-item {
    margin-right: 8px;
    color: #fff;
  }
}
.more {
  cursor: pointer;
  position: absolute;
  left: @size150;
  bottom: @size140;
  font-size: @font18;
  cursor: pointer;
  color: #fff;
}
// .pics-content {
//   position: absolute;
//   top: @size200;
//   left: @size150;
//   color: #fff;
//   text-align: left;
//   .words {
//     font-size: @size80;
//     font-weight: 700;
//   }
//   .lastline {
//     margin-bottom: @size40;
//   }
//   .words-en {
//     font-size: @size30;
//     font-weight: 700;
//   }
// }
</style>
