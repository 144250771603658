<template>
  <div class="news-breadcrumb">
    <div class="breadcrumb">
      <span
        class="breadcrumb-item"
        v-for="(name, index) in breadcrumb"
        :key="index"
        >{{ name }}</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsBreadcrumb',
  data() {
    return {
      breadcrumb: ['首页 >', '新闻资讯 >', '正文'],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.news-breadcrumb {
  height: @size50;
  background: #0066dd;
  padding-left: @size150;
  margin-top: @size90;
  text-align: left;
  .breadcrumb-item {
    margin-right: 8px;
    color: #fff;
    font-size: @font14;
    line-height: 50px;
  }
}
</style>
