<template>
  <div class="page">
    <div class="wrapper">
      <div class="inner">
        <div class="item first" @click="onFirst">首页</div>
        <div class="item prev" @click="onPrev" v-if="page > 1">上一页</div>
        <div
          class="item"
          v-for="(item, index) in page"
          :key="index"
          :class="current == index + 1 ? 'active' : ''"
          @click="onClick(index + 1)"
        >
          {{ index + 1 }}
        </div>
        <div class="item next" @click="onNext" v-if="page > 1">下一页</div>
        <div class="item end" @click="onEnd" v-if="page > 1">末页</div>
        <div class="item total">
          共{{ total }}条 第{{ current }}/{{ page }}页
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Page',
  props: {
    page: Number,
    total: Number,
    onPageChange: Function,
  },
  data() {
    return {
      current: 1,
    };
  },
  methods: {
    onFirst() {
      this.onClick(1);
    },
    onEnd() {
      this.onClick(this.page);
    },
    onPrev() {
      if (this.current <= 1) {
        return;
      }
      this.onClick(this.current - 1);
    },
    onNext() {
      if (this.current >= this.page) {
        return;
      }
      this.onClick(this.current + 1);
    },
    onClick(page) {
      this.current = page;
      if (this.onPageChange) {
        this.onPageChange(page);
      }
    },
  },
};
</script>
<style scoped lang="less">
.news {
  width: 100%;
  .wrapper {
    width: 100%;
    display: flex;
    justify-content: center; /* 水平居中 */
    .inner {
      width: @size1200;
      min-width: 600px;
      display: flex;
      justify-content: center; /* 水平居中 */
      align-items: center; /* 垂直居中 */
      .item {
        border: 1px solid #989898;
        padding: 0 10px;
        border-radius: 16px;
        font-size: @font16;
        color: #575757;
        cursor: pointer;
        margin-right: 10px;
        line-height: 32px;
        height: 32px;
      }
      .active,
      .total {
        background: #0066dd;
        color: #fff;
      }
    }
  }
}
</style>
