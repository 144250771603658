<template>
  <div class="news-detail">
    <div class="wrapper">
      <div class="content">
        <div class="date">
          {{ detail.date }}
        </div>
        <div class="title">
          {{ detail.title }}
        </div>
        <div class="divider"></div>
        <div
          class="content-item"
          v-for="(info, infoIndex) in detail.detail || []"
          :key="infoIndex"
        >
          <p v-if="!info.image">{{ info.value }}</p>
          <img :src="info.image" v-if="info.image" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewsDetail',
  props: {
    detail: Object,
  },
};
</script>
<style scoped lang="less">
.wrapper {
  width: 100%;
  background: #fff;
  display: flex;
  justify-content: center; /* 水平居中 */
  padding-top: @size80;
}
.content {
  width: @size1200;
  min-width: 600px;
  text-align: center;
  background: #fff;
  padding-bottom: @size100;
  .date {
    font-size: @size24;
    color: #666;
    margin-bottom: @size24;
    text-align: left;
  }
  .title {
    font-size: @size40;
    font-weight: 700;
    text-align: left;
    color: #666;
  }
  .divider {
    width: 100%;
    height: 1px;
    background: #666;
    margin-bottom: @size100;
    margin-top: @size80;
  }
  p {
    text-align: left;
    color: #666;
    font-size: @font18;
    line-height: 1.875;
  }
  img {
    width: @size1000;
    min-width: 500px;
    height: auto;
    margin-top: @size100;
    margin-bottom: @size100;
  }
}
</style>
