<template>
  <div class="news">
    <Header theme="black" :currentIndex="headerIndex" />
    <NewsPics />
    <NewsCards class="news-cards" :newsItems="currentNews" />
    <Page
      class="page"
      :page="page"
      :total="total"
      :onPageChange="onPageChange"
    />
    <Footer />
    <div class="footer"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import NewsPics from '@/components/NewsPics.vue';
import NewsCards from '@/components/NewsCards.vue';
import Page from '@/components/Page.vue';
import newsItems from '../utils/news';

export default {
  name: 'News',
  components: {
    Header,
    Footer,
    NewsPics,
    NewsCards,
    Page,
  },
  created() {
    this.page = Math.ceil(this.total / this.pageSize);
    this.onPageChange(1);
  },
  data() {
    return {
      headerIndex: 3,
      currentNews: [],
      current: 1,
      pageSize: 6,
      total: newsItems.length,
      page: 1,
    };
  },
  methods: {
    onPageChange(page) {
      if (page < 1) {
        return;
      }
      if (page > this.page) {
        return;
      }
      const start = (page - 1) * this.pageSize;
      const end = page * this.pageSize;
      this.currentNews = newsItems.slice(start, end);
    },
  },
};
</script>
<style lang="less" scoped>
.news-cards {
  margin-top: @size100;
}
.page {
  margin-bottom: @size100;
}
</style>
